<template>
  <v-card flat class="card-icon" :to="routePath">
    <img
      v-if="icon"
      :src="iconPath"
      :width="$vuetify.breakpoint.xs ? 36 : 50"
      :height="$vuetify.breakpoint.xs ? 36 : 42"
    />
    <v-card-title class="text-center">
      <h3 class="primary--text text--lighten-1">{{ title }}</h3>
    </v-card-title>
    <v-card-subtitle v-if="subtitle"> {{ subtitle }}</v-card-subtitle>
    <v-card-text v-if="buttonText">
      <v-btn class="primary-button" large depressed>
        {{ buttonText }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DashboardCard",
  props: {
    title: { type: String },
    subtitle: { type: String },
    icon: { type: String },
    buttonText: { type: String },
    routePath: { type: String }
  },
  computed: {
    iconPath() {
      return `/img_layout/dashboard/${this.icon}.svg`;
    }
  }
};
</script>
