<template>
  <div class="dashboard">
    <v-container v-if="user">
      <v-row no-gutters class="dashboard-title">
        <v-col cols="12" class="d-flex flex-column align-center justify-center">
          <h3 class="primary--text text--lighten-1">
            Ciao {{ user.firstName }}!
          </h3>
          <p>
            In questa sezione puoi trovare tutti i dettagli del tuo account e le
            tue preferenze
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" sm="6">
          <DashboardCard
            :title="'I miei ordini'"
            :icon="'ordini'"
            :routePath="'/profile/orders'"
          />
        </v-col>
        <v-col cols="6" sm="6">
          <DashboardCard
            :title="'I miei preferiti'"
            :icon="'liste'"
            :routePath="'/profile/lists'"
          />
        </v-col>
        <v-col cols="6" sm="6">
          <DashboardCard
            :title="'I miei indirizzi'"
            :icon="'indirizzi'"
            :routePath="'/profile/addresses'"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="scss">
.dashboard {
  .dashboard-title {
    margin-bottom: 20px;
  }
  h3 {
    font-size: 30px;
    word-break: break-word;
  }
  // p {
  //   font-size: 22px;
  // }
  .v-card {
    min-height: 150px;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    .v-card__text {
      align-items: center;
      justify-content: center;
      display: flex;
    }
    .v-card__title {
      font-size: 24px;
      color: $text-color;
      font-weight: bold;
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        h3 {
          font-size: 20px;
        }
      }
    }
    .v-card__subtitle {
      color: $card-text-color;
      font-size: 18px;
    }
    .v-btn {
      font-size: 14px;
      font-weight: normal;
      padding: 0px 30px;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    h3 {
      font-size: 24px;
    }
    .v-card {
      min-height: 180px;
      .v-card__title {
        min-height: 90px;
      }
    }
  }
}
</style>
<script>
// @ is an alias to /src
import RegistrationService from "~/service/userService";
import SecurityService from "~/service/securityService";
import DashboardCard from "@/components/profile/DashboardCard";

export default {
  name: "Profile",
  data() {
    return { user: null };
  },
  created() {
    this.fetchUserData();
  },
  components: {
    DashboardCard
  },
  methods: {
    logout(dialog) {
      SecurityService.logout().then(() => {
        // TODO GPA: Al Logout va alla Lista degli Stores
        this.$router.push("/stores");
        dialog.close();
      });
    },
    fetchUserData() {
      RegistrationService.getUserDetail().then(user => {
        this.user = user;
      });
    }
  }
};
</script>
